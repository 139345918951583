import React from "react";
import { Col, Container, Nav, Navbar, NavDropdown, Row } from "react-bootstrap";
import { menuItems } from "../../../data/menudata";
import { Link } from "gatsby";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import arrow from '~assets/image/menu/right-arrow.png';
import img from '~assets/image/menu/Sales-marketing.svg';
import img2 from '~assets/image/menu/Recruiters.svg';
import img3 from '~assets/image/menu/Entrepreneurs-Experts.svg';
import img4 from '~assets/image/menu/Linkedin-Automation.svg';
import img5 from '~assets/image/menu/Email-Automation.svg';
import img6 from '~assets/image/menu/AI-Assist.svg';

const isObject = function (a) {
  return !!a && a.constructor === Object
}
const Menu = ({...rest}) => {
  
  return (
    <>
    
      <Navbar.Collapse id="responsive-navbar-nav" {...rest} >
          <Navbar.Toggle aria-controls="responsive-navbar-nav" className="d-lg-none offcanvas-close"/>
            <Nav className="mr-auto site-menu-main">
              {menuItems.map(
                (
                  {
                    label,
                    content,
                    isExternal = false,
                    icon,
                    name,
                    elink,
                    home,
                    items,
                    megamenu,
                    col,
                    ...rest
                  },
                  index
                ) => {
                  const hasSubItems = Array.isArray(items)
                  const hasMegaMenu = isObject(items)

                  return (
                    <React.Fragment key={name + index}>
                      {hasMegaMenu && ( 
                        <NavDropdown
                          collapseOnSelect     
                          title={label}
                          href={`${home}`}
                          id={name + index}
                          renderMenuOnMount={true}
                          className={`main-nav-item megadropdown`}                          
                          >
                            <Container>
                          
                            
                            {col > 0 && (
                              <>
                                <Col
                                  lg={col}
                                  className="mega-dropdown-menu-block row"
                                >
                                  {items.col_1 && (
                                    <Col className="col-xl-6 col-lg-6 col-xs-4 col-md-4">
                                      {items.col_1.title && <span className="megamenu-column-title">{items.col_1.title}</span>}
                                      
                                          <Row>
                                            <Col className="col-xl-2 col-lg-2 col-xs-3 col-md-3">
                                            <img src={img} alt="menu" className="megamenu-image" />
                                            </Col>
                                            <Col className="col-xl-10 col-lg-10 col-xs-9 col-md-9" >
                                              
                                            <Nav.Link href="/sales-marketing" className="megamenu-nav-item" eventKey="1"  rel="noopener noreferrer">
                                              <AnchorLink>Sales & Marketing
                                              <span>Reach and engage with the right buyers, leverage your network, and ensure high-quality, consistent LinkedIn sales outreach</span>
                                              </AnchorLink>
                                            </Nav.Link>
                                          </Col>
                                          </Row>
                                          <Row>
                                            <Col className="col-xl-2 col-lg-2 col-xs-3 col-md-3">
                                            <img src={img2} alt="menu" className="megamenu-image" />
                                            </Col>
                                            <Col className="col-xl-10 col-lg-10 col-xs-9 col-md-9">
                                            <Nav.Link href="/recruiters" className="megamenu-nav-item" eventKey="2">
                                          <AnchorLink>
                                              Recruiters
                                              <span>Locate better candidates faster, spend less time sourcing. and start conversations with those elusive, top-notch prospects.</span>
                                              </AnchorLink> 
                                          </Nav.Link>
                                          </Col>
                                          </Row>
                                          <Row>
                                            <Col className="col-xl-2 col-lg-2 col-xs-3 col-md-3">
                                            <img src={img3} alt="menu" className="megamenu-image" />
                                            </Col>
                                            <Col className="col-xl-10 col-lg-10 col-xs-9 col-md-9"> 
                                            <Nav.Link href="/entrepreneurs" className="megamenu-nav-item" eventKey="3">
                                          <AnchorLink>
                                          Entrepreneurs & Experts
                                              <span>Amplify your message, connect with industry peers, and forge meaningful partnerships.</span>
                                              </AnchorLink> 
                                          </Nav.Link>
                                          </Col>
                                          </Row>
                                    </Col>
                                  )}
                                  {items.col_2 && (
                                    <Col className="col-xl-5 col-lg-5 col-xs-4 col-md-4">
                                    {items.col_1.title && <span className="megamenu-column-title">{items.col_2.title}</span>}
                                    
                                        <Row>
                                          <Col className="col-xl-2 col-lg-2 col-xs-3 col-md-3">
                                          <img src={img4} alt="menu" className="megamenu-image" />
                                          </Col>
                                          <Col className="col-xl-10 col-lg-10 col-xs-9 col-md-9" >
                                            
                                          <Nav.Link href="/linkedin-automation" className="megamenu-nav-item" eventKey="1"  rel="noopener noreferrer">
                                            <AnchorLink>LinkedIn Automation
                                            <span>With the most safe, advanced and affordable LinkedIn outreach platform.</span>
                                            </AnchorLink>
                                          </Nav.Link>
                                        </Col>
                                        </Row>
                                        <Row>
                                          <Col className="col-xl-2 col-lg-2 col-xs-3 col-md-3">
                                          <img src={img5} alt="menu" className="megamenu-image" />
                                          </Col>
                                          <Col className="col-xl-10 col-lg-10 col-xs-9 col-md-9">
                                          <Nav.Link href="/email-automation" className="megamenu-nav-item" eventKey="2">
                                        <AnchorLink>
                                            Email Automation
                                            <span>Automate your email outreach within We-Connect campaigns.</span>
                                            </AnchorLink> 
                                        </Nav.Link>
                                        </Col>
                                        </Row>
                                        <Row>
                                          <Col className="col-xl-2 col-lg-2 col-xs-3 col-md-3">
                                          <img src={img6} alt="menu" className="megamenu-image" />
                                          </Col>
                                          <Col className="col-xl-10 col-lg-10 col-xs-9 col-md-9"> 
                                          <Nav.Link href="/ai-assist" className="megamenu-nav-item" eventKey="3">
                                        <AnchorLink>
                                        AI Assist
                                            <span>Smart AI features to engage your prospects better and faster.</span>
                                            </AnchorLink> 
                                        </Nav.Link>
                                        </Col>
                                        </Row>
                                        <Row>
                                          <Col className="col-xl-6 col-lg-6 col-xs-6 col-md-6">
                                          <div>
                                        <Nav.Link href="/integrations" >
                                        <AnchorLink className="integrations-arrow" >
                                          <span>Integrations</span> <img src={arrow} alt="arrow" />
                                        </AnchorLink>
                                        </Nav.Link>
                                      </div>
                                          </Col>
                                      <Col className="col-xl-6 col-lg-6 col-xs-6 col-md-6">
                                      <div>
                                        <Nav.Link href="/features" >
                                        <AnchorLink className="features-arrow" >
                                          <span>All Features</span> <img src={arrow} alt="arrow" />
                                        </AnchorLink>
                                        </Nav.Link>
                                      </div>
                                      </Col>
                                        </Row>
                                  </Col>
                                    
                                  )}
                                  
                                </Col>
                              </>
                            )}
                          
                          </Container>
                        </NavDropdown>
                      )}
                      {hasSubItems && (
                        <NavDropdown
                          title={label}
                          id={name + index}
                          className="main-dropdown main-nav-item"
                          renderMenuOnMount={true}
                          {...rest}
                        >
                          {items.map((subItem, indexSub) => {
                            const hasInnerSubItems = Array.isArray(
                              subItem.items
                            )
                            return (
                              <React.Fragment key={subItem.name + indexSub}>
                                {hasInnerSubItems ? (
                                  <NavDropdown
                                    title={subItem.label}
                                    id={subItem.name + indexSub}
                                    renderMenuOnMount={true}
                                    className="drop-menu-item innerDropdown"
                                   
                                  >
                                    {subItem.items.map(
                                      (itemInner, indexInnerMost) => (
                                        <Nav.Link
                                          className="drop-menu-item"
                                          key={itemInner.name + indexInnerMost}
                                        >
                                          {itemInner.isExternal ? (
                                            <NavDropdown.Item
                                              href={`${itemInner.name}`}
                                              target="_blank"
                                            >
                                              {itemInner.label}
                                            </NavDropdown.Item>
                                          ) : (
                                            <Link to={`/${itemInner.name}`}>
                                              {itemInner.label}
                                            </Link>
                                          )}
                                        </Nav.Link>
                                      )
                                    )}
                                  </NavDropdown>
                                ) : (
                                  <>
                                    {subItem.isExternal ? (
                                      <Nav.Item className="drop-menu-item" >
                                        <NavDropdown.Item
                                          href={`${subItem.name}`}
                                          target="_blank"
                                        >
                                          {subItem.label}
                                        </NavDropdown.Item>
                                      </Nav.Item>
                                    ) : (
                                      <Nav.Item className="drop-menu-item">
                                        <a
                                          href={`/${subItem.name}`}
                                          rel="noopener noreferrer"
                                          target= {`${subItem.elink == true ? '_blank' : '_self'}`}
                                        >
                                          {subItem.label}
                                        </a>
                                      </Nav.Item>
                                    )}
                                  </>
                                )}
                              </React.Fragment>
                            )
                          })}
                        </NavDropdown>
                      )}

                      {(!hasSubItems && !hasMegaMenu) && (
                        <>
                          {isExternal ? (
                            <Nav.Item className="login-button">
                              <Nav.Link
                                className={`${name.includes("account") ? 'd-lg-none nav-link' : 'nav-link' }`}
                                href={`${name}`}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {label}
                              </Nav.Link>
                            </Nav.Item>
                          ) : (
                            <Nav.Item className="main-nav-item">
                              <Nav.Link
                                as={Link}
                                className="nav-link"
                                href={`/${name}`}
                                role="button"
                                aria-expanded="false"
                                eventKey="6"
                              >
                                {label}
                              </Nav.Link>
                            </Nav.Item>
                          )}
                        </>
                      ) }
                    </React.Fragment>
                  )
                }
              )}
            </Nav>
          </Navbar.Collapse>
          
          </>
  );
};
export default Menu;
